<template>
  <div>
    <trac-loading v-if="isLoading" />
    <main v-if="GET_ALL_TERMINAL_PRICINGS && GET_ALL_TERMINAL_PRICINGS.data">
      <div>
        <trac-back-button>Hardware</trac-back-button>
        <h1 class="text-md mt-2 font-medium">Request Terminal</h1>
      </div>
      <div class=" big-shadow w-full py-24 px-5 mt-10 rounded-xl">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div v-if="one" class=" small-shadow rounded-lg   ">
            <p class=" text-center text-sm to-gray-800 pt-6  font-medium">
              Traction SOLO
            </p>
            <div class="flex items-center mt-6 justify-between px-12">
              <div>
                <img class="h-40" src="@/assets/images/terminals/TractionMpos.png" alt="" />
              </div>
              <div class="ml-3">
                <div>
                  <h1 class="text-xs text-primaryGray">Outright purchase</h1>
                  <h2 class="text-xs">₦{{ mposTerminal.outrightPurchase }}</h2>
                </div>
                <div class="mt-2">
                  <h1 class="text-xs text-primaryGray">Lease to own</h1>
                  <h2 class="text-xs ">₦{{ mposTerminal.leaseToOwn }}</h2>
                </div>
              </div>
            </div>
            <div class=" px-4 mt-4">
              <trac-button data-test="transaction-solo-place-request" @click.native="$router.push({ name: 'TerminalRequestMpos' })" buttonType="submit"
                class="w-full mb-5 mt-12 ">
                <div class="py-1">PLACE REQUEST</div>
              </trac-button>
            </div>
          </div>
          <div v-if="two" class=" small-shadow rounded-lg p-4">
            <p class=" text-center text-sm to-gray-800 mt-2 font-medium">
              Traction PRO
            </p>
            <div class="flex items-center mt-10 justify-between px-2">
              <div>
                <img src="@/assets/images/terminals/TractionLinux.png" class="h-40" alt="" />
              </div>
              <div>
                <div class="ml-1">
                  <div>
                    <h1 class="text-xs text-primaryGray">Outright purchase</h1>
                    <h2 class="text-xs">
                      ₦{{ linuxTerminal.outrightPurchase }}
                    </h2>
                  </div>
                  <div class="mt-2">
                    <h1 class="text-xs text-primaryGray">Lease to own</h1>
                    <h2 class="text-xs ">
                      ₦{{ linuxTerminal.leaseToOwn }}(Per day for full term of
                      lease)
                    </h2>
                  </div>
                  <div class="mt-2">
                    <h1 class="text-xs text-primaryGray">Subscription</h1>
                    <h2 class="text-xs ">
                      ₦{{ linuxTerminal.subscription }}(Per day)
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <trac-button  @click.native="$router.push({ name: 'TerminalRequestLinux' })" class="w-full mb-5 mt-12">
              <div class="py-1">PLACE REQUEST</div>
            </trac-button>
          </div>
          <div v-if="three" class=" small-shadow rounded-lg p-4  ">
            <p class=" text-center text-sm to-gray-800 mt-2  font-medium">
              Traction ONE
            </p>
            <div class="flex items-center mt-10 justify-between px-4">
              <div>
                <img src="@/assets/images/terminals/TractionAndroid.svg" class="h-40" />
              </div>
              <div>
                <div>
                  <h1 class="text-xs text-primaryGray">Outright purchase</h1>
                  <h2 class="text-xs">
                    ₦{{ androidTerminal.outrightPurchase }}
                  </h2>
                </div>
                <div class="mt-2">
                  <h1 class="text-xs text-primaryGray">Lease to own</h1>
                  <h2 class="text-xs ">
                    ₦{{ androidTerminal.leaseToOwn }}(Per day for full term of
                    lease)
                  </h2>
                </div>
                <div class="mt-2">
                  <h1 class="text-xs text-primaryGray">Subscription</h1>
                  <h2 class="text-xs ">
                    ₦{{ androidTerminal.subscription }}(Per day)
                  </h2>
                </div>
              </div>
            </div>
            <trac-button data-test="send-request" @click.native="$router.push({ name: 'TerminalRequestAndroid' })" class="w-full mb-5 mt-12">
              <div class="py-1">PLACE REQUEST</div>
            </trac-button>
          </div>
        </div>
      </div>
    </main>

    <trac-modal v-if="modal && !accountNotVerified" :cancel="false" size=" max-w-xl">
      <div class="lg:ml-16 ml-2">
        <h1 class="   text-lg  font-bold mt-10">
          Let’s help you choose the right POS
        </h1>
        <p class=" text-xs mt-1">
          Which of these activities do you want to perform on <br />
          Traction.
        </p>

        <div class="mt-5 flex flex-col gap-5">
          <trac-checkbox data-test="accept-card-payment-from-customer" @check="selectOptions" value="one">
            <p class="ml-3 text-xs">Accept card payments from customers</p>
          </trac-checkbox>
          <trac-checkbox data-test="accept-bank-transfer-using-traction-account" @check="selectOptions" value="two">
            <p class="ml-3 text-xs">
              Accept bank transfers using Traction account
            </p>
          </trac-checkbox>
          <trac-checkbox data-test="manage-my-product-and-inventory" @check="selectOptions" value="three">
            <p class="ml-3 text-xs">Manage my products and inventory</p>
          </trac-checkbox>
        </div>
        <div class="text-right mt-10 mb-5 mr-5">
          <trac-button data-test="continue-button" @click.native="checkOptions">
            <span class=" px-4">Continue</span>
          </trac-button>
        </div>
      </div>
    </trac-modal>
    <trac-modal v-if="accountNotVerified" :cancel="false" size=" max-w-xl">
      <div class="flex justify-center items-center gap-6 flex-col">
        <img src="./../../assets/svg/warning_amber.svg" alt="" class="">
        <p class="text-xs text-center">Kindly note that you will need to complete your KYC contact verification before
          you can request for a
          terminal.</p>

        <trac-button @button-clicked="routeToKYC" class="block mt-8">COMPLETE CONTACT VERIFICATION</trac-button>
      </div>
    </trac-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      modal: true,
      accountNotVerified: false,
      options: [],
      one: false,
      two: false,
      phoneData: null,
      three: false,
    };
  },

  computed: {
    ...mapGetters(["GET_ALL_TERMINAL_PRICINGS"]),
    terminalRequestKycVerified() {
      return ((this.phoneData.status === 'OVERRIDE' || this.phoneData.status === 'VERIFIED') && 
      (this.emailData.status === 'OVERRIDE' || this.emailData.status === 'VERIFIED'));
    },
    mposTerminal() {
      return this.GET_ALL_TERMINAL_PRICINGS.data.items.find(
        (x) => x.terminalType === "MPOS"
      );
    },
    linuxTerminal() {
      return this.GET_ALL_TERMINAL_PRICINGS.data.items.find(
        (x) => x.terminalType === "LINUX"
      );
    },
    androidTerminal() {
      return this.GET_ALL_TERMINAL_PRICINGS.data.items.find(
        (x) => x.terminalType === "ANDROID"
      );
    },
  },
  methods: {
    routeToKYC() {
      if (this.phoneData.status === "" && this.emailData.status === "") {
        this.$router.push({
          name: "VerifyEmail", query: {
            custom: true,
          }
        });
      } else if (this.phoneData.status === "" && this.emailData.status === "VERIFIED") {
        this.$router.push({
          name: "VerifyPhone", query: {
            custom: true,
          }
        });
      } else {
        this.$router.push({
          name: "ExistingPhoneEmail", query: {
            custom: true,
          }
        });
      }
    },
    async checkVerification() {
      let result = await this.$store.dispatch("VERIFY_KYC");
      const status = result.data;
      const account = status.find(({ name }) => name === "account");
      const phone = status.find(({ name }) => name === "phone");
      const email = status.find(({ name }) => name === "email");
      this.phoneData = phone;
      this.emailData = email;

      // const document = status.find(({ name }) => name === "document");
      // const bvn = status.find(({ name }) => name === "bvn");
    },
    selectOptions(e) {
      if (e.checked) {
        this.options.push(e.value);
      } else {
        this.options = this.options.filter(
          (option) => option.toLowerCase() !== e.value.toLowerCase()
        );
      }
    },
    checkOptions() {
      if (this.options.length === 3) {
        this.one = true;
        this.two = true;
        this.three = true;
        this.modal = false;
        return;
      }

      if (this.options.includes("one") && this.options.includes("two")) {
        this.one = true;
        this.two = true;
        this.three = true;
        this.modal = false;
        return;
      }

      if (this.options.includes("two") && this.options.includes("three")) {
        this.three = true;
        this.modal = false;
        return;
      }

      if (this.options.includes("one") && this.options.length === 1) {
        this.one = true;
        this.two = true;
        this.modal = false;
        return;
      }

      if (
        (this.options.includes("two") && this.options.length === 1) ||
        (this.options.includes("three") && this.options.length === 1)
      ) {
        this.three = true;
        this.modal = false;
        return;
      }

      if (this.options.includes("one") && this.options.includes("three")) {
        this.one = true;
        this.three = true;
        this.modal = false;
        return;
      }
    },
  },
  async created() {
    this.isLoading = true;

    const res = await this.checkVerification();

    if (this.terminalRequestKycVerified) {
      await this.$store.dispatch("FETCH_TERMINAL_PRICINGS");
    } else {
      this.accountNotVerified = true;
    }

    this.isLoading = false;
  },
};
</script>

<style>

</style>
